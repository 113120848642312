<mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav-side" mode="side" opened>
        <div class="">
            <span class="results-found">Search Options</span>
            <mat-action-list>
                <mat-list-item *ngFor="let p of data?.sections; let idx = index" (click)="setSection(idx)" [ngClass]="{'active-section' : idx  == sectionIdx}" >
                    <!-- mat-icon *ngIf="isSectionDirty(idx)" style="color: deepskyblue;">lens_blur</mat-icon>&nbsp;&nbsp; -->
                    <span>{{ idx + 1 }}/{{ data?.sections.length }} - {{ p.name }}</span>
                </mat-list-item>
            </mat-action-list>
            <hr>
            <div class="results-found">
                <div class="view-results" (click)="viewResults = true">{{ getNumberResults()?.length }} results</div><br>
                <ng-container *ngIf="viewResults; else searchTools" >
                    <!-- Result Tools Starts -->
<!--                    <button mat-flat-button (click)="setSection(0)" class="cuas-primary-button" >Start Over</button>-->
                    <button mat-flat-button (click)="resetSection()" class="cuas-primary-button" >Start Over</button>
                    <!-- Result Tools Ends -->
                </ng-container>

                <ng-template #searchTools>
                    <!-- Search Tools Starts -->
                    <button mat-flat-button class="cuas-primary-button" color="" (click)="setViewResults()" >
                        View Results <mat-icon>arrow_forward</mat-icon>
                    </button>
                    <!-- Search Tools Ends -->
                </ng-template>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
            <!-- Results Starts-->
            <div *ngIf="viewResults; else Questionaire" class="container results">
                <h2>{{ getResults()?.length }} products
                    <div class="rightToolbar">
                        <button mat-flat-button (click)="showAll = !showAll" >{{ showAll ? 'Show Results' : 'Show All Products' }}</button>
                        <!--mat-select [(value)]="tolerance">
                            <mat-option *ngFor="let tol of [ 100, 90, 80, 70, 60, 50, 40, 30, 20, 10 ]" [value]="tol">{{ tol }}% Match</mat-option>
                        </mat-select-->
                        <!--button mat-flat-button (click)="showSummary = !showSummary" >{{ showSummary ? 'Hide' : 'Show' }} Search Criteria Summary</button-->
                    </div>
                </h2>
                <!-- Selected Properties Starts -->
                <div class="summary" *ngIf="showSummary">
                    <ng-container *ngFor="let section of data?.sections">
                        <ng-container *ngFor="let subsection of section.sections">
                            <h3>{{ subsection.name }}</h3>
                            <div *ngFor="let p of getProperties(subsection)" [class]="p.active ? 'active': ''">
                                <label>{{ p.name }}</label>
                                <span *ngIf="p.kind == 'slider'" >${{ p.settings.value | number:'1.0':'en-US' }}</span>
                                <span *ngIf="p.kind != 'slider'" >{{ p.settings.value.toString().replace(',', ', ') }}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- Selected Properties Ends -->
                <div *ngFor="let product of getResults()" class="result" >
                    <h3>{{ product.name }}<!--small>{{ product.match }}% Match</small--></h3>
                    <table>
                        <tr>
                            <th>Manufacturer:</th><td><a href="{{ product.manufacturer.website }}" target="_blank">{{ product.manufacturer.name }}</a></td>
                            <th></th><td></td>
                            <td rowspan="3"><span class="material-icons verified-badge floated" *ngIf="product.verified">verified</span></td>
                        <tr>
                            <th>Price:</th><td>{{ propertyAsNumber(product, 1) | currency:'USD' : 'symbol' : '1.0-0' }}</td>
                            <th>Country:</th><td [innerHTML]="property(product, 3)"></td>
                        </tr>
                        <tr>
                            <th>Website:</th><td [innerHTML]="dataProperty(product, [48,51,53], '<br>')"></td>
                            <th>Detection:</th><td [innerHTML]="dataProperty(product, [47,49,50], '<br>')"></td>
                        </tr>
                        <tr>
                            <td colspan="4" style="word-wrap: break-word ">
                                {{ product.description }}
                            </td>
                        </tr>
                    </table>
                    <h3 class="report-title">Reports</h3>
                    <div class="report-section" *ngFor="let report of getProductReports(product.id)">
                        <div class="report-header">
                           <h3>{{report.display_name}}</h3>
                           <div>
                               <span *ngIf="report.test_event">{{report.test_event}}</span>
                               <span *ngIf="report.test_report_location">{{report.test_report_location}} - </span>
                               <span *ngIf="report.test_report_date">{{report.test_report_date}}</span>
                               <span class="material-icons-outlined verified-badge small" *ngIf="report.is_verified">verified</span>
                               <span class="material-icons toggle" (click)="report.showSummary = true" *ngIf="!report.showSummary">expand_more</span>
                               <span class="material-icons toggle" (click)="report.showSummary = false" *ngIf="report.showSummary">expand_less</span>
                           </div>
                        </div>

                       <div class="summary" *ngIf="report.showSummary">
                           <ng-container *ngFor="let section of data?.sections">
                               <ng-container *ngFor="let subsection of section.sections">
                                   <div *ngIf="sectionReportHasValues(subsection, product, report)">
                                       <h4>{{ subsection.name }}</h4>
                                       <div *ngFor="let p of getSectionReportProperties(subsection, product, report)" [class]="p.active ? 'criteria': ''">
                                           <label><span class="material-icons-outlined verified-badge small" *ngIf="p.is_verified">verified</span> {{ p.name }}</label>
                                           <span class="prop-val" *ngIf="p.kind == 'slider'">${{ propertyAsNumber(product,  p.id) | number:'1.0':'en-US' }}</span>
                                           <span class="prop-val" *ngIf="p.kind != 'slider'">{{ property(product,  p.id) }}</span>
                                       </div>
                                   </div>
                               </ng-container>
                           </ng-container>
                       </div>
                        <span class="small-date">Date last updated: {{report.updated_at | date:'MM/dd/yy'}}</span>
                    </div>

                </div>
            </div>
            <!-- Results Ends -->

            <!-- Search Questionaire Starts-->
            <ng-template #Questionaire>
                <ng-container *ngFor="let section of data?.sections; let idx = index">
                    <div class="container" *ngIf="sectionIdx == idx">
                        <div *ngFor="let subsection of section.sections" [class]="section.settings?.class">
                            <h2>{{ subsection.name }}</h2>
                            <p *ngIf="subsection.help">{{ subsection.help }}</p>
                            <!-- Properties Starts -->
                            <div class="properties">
                                <div *ngFor="let property of getProperties(subsection)" [class]="property.settings?.class">
                                    <h3 [ngClass]="{'disabled' : !property.searchable}">{{ property.name }} <mat-icon [matTooltip]="property.help" matTooltipPosition="right" *ngIf="property.help">help_outline</mat-icon></h3>
                                    <!-- Slider -->
                                    <mat-slider [min]="property.settings.min" [max]="property.settings.max" [step]="property.settings.step" [value]="property.settings.value" [displayWith]="displayCurrency" *ngIf="property.kind == 'slider'"  (change)="searchFilters($event, property)"></mat-slider>
                                    <!-- Select -->
                                    <mat-form-field *ngIf="property?.kind == 'select'">
                                        <mat-select [value]="property.settings.value" [multiple]="property.settings.multiple" (selectionChange)="searchFilters($event, property)" [disabled]="!property.searchable">
                                            <mat-option *ngFor="let option of property.settings.options" [value]="option">{{ option }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!--span *ngIf="property?.kind == 'select' && isDirty(property, sectionIdx)"><mat-icon style="color: deepskyblue;">lens_blur</mat-icon></span -->
                                </div>
                            </div>
                            <!-- Properties Starts -->
                        </div>
                        <div class="questionaire-nav-buttons">
                            <button mat-flat-button class="cuas-primary-button button-left"  (click)="setSection(sectionIdx - 1)" *ngIf="sectionIdx > 0">
                                <mat-icon>arrow_back</mat-icon> Previous
                            </button>
                            <button mat-flat-button class="cuas-primary-button button-right" (click)="setSection(sectionIdx + 1)" *ngIf="sectionIdx < data?.sections.length - 1">
                                Next <mat-icon>arrow_forward</mat-icon>
                            </button>
                            <button mat-flat-button class="cuas-primary-button button-right" (click)="setViewResults()" *ngIf="sectionIdx == data?.sections.length - 1">
                                View Results <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="container" *ngIf="!dataLoaded">
                <div class="loading-indicator">
                    <app-loading-indicator></app-loading-indicator>
                </div>
                </div>
            </ng-template>
            <!-- Search Questionaire Ends -->



    </mat-sidenav-content>
</mat-sidenav-container>
